.publication {
  h1 {
    text-align: center;
  }

  .authors {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 0 $double-space;

    div {
      text-align: center;
      display: inline-block;
      margin: $single-space $half-space;

      p, i, a {
        margin: 0;
      }
    }
  }

  .journal {
    text-align: center;
  }

  .teaser {
    > img {
      width: 100%;
    }
  }

  .icons {
    margin-top: $double-space;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    a {
      margin-left: $half-space;
    }

    a.ai {
      color: $base-black;
      text-decoration: none;
    }
  }

  .bibtex {
    > pre {
      font-size: $small-font-size;
      line-height: 1.15em;
      // from: https://www.longren.io/wrapping-text-inside-pre-tags/
      white-space: pre-wrap;       /* Since CSS 2.1 */
      white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
      white-space: -pre-wrap;      /* Opera 4-6 */
      white-space: -o-pre-wrap;    /* Opera 7 */
      word-wrap: break-word;       /* Internet Explorer 5.5+ */
    }
  }

  .figures {
    img {
      width: 100%;
    }

    .figure {
      padding: $half-space;
    }
  }

}

.publication-list {
  ul {
    padding: 0;

    .pub-li {
      @media screen and (min-width: 48em) {
        display: flex;
      }
    }

    li {
      list-style: none;
      margin-top: $single-space;
      margin-bottom: $double-space;

      .image-container-container {
        $image-width: 10em;
        $aspect-ratio: 2;

        @media screen and (max-width: 47.999em) {
          display: flex;
          justify-content: center;
          width: 100%;
          margin-bottom: 0.5em;
        }

        @media screen and (min-width: 48em) {
          display: inline-block;
          height: $image-width / $aspect-ratio;
          width: $image-width;
        }

        .image-container {
          background-size: cover;
          background-position: center;
          padding-top: $image-width / $aspect-ratio;
          width: $image-width;
        }
      }

      .ai-wrap {
        position: relative;
        top: -5px;
      }

      a.ai {
          color: $base-black;
          text-decoration: none;
      }

      h4 {
          margin: 0;
          line-height: $single-space;
      }

      .meta-data {
        > div {
          margin-left: $single-space;
        }
      }

      .bibtex-icon {
        padding-left: 19px;
        background-size: cover;
        height: 100%;
        background-image: url("/images/bibtex.png");
      }

      i {
        margin-right: 0.25em;
      }

      @media screen and (max-width: 47.999em) {
        margin-bottom: $double-space * 2;
      }
    }
  }
}
