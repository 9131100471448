.team {
  margin-left: $single-space;
  margin-right: $single-space;

  .member-category {
    padding: 0;
    margin-top: $single-space;

    .member-list {

      .member {
        display: flex;
        align-items: center;
        margin-bottom: $single-space;
        list-style: none;
        float: left;

        .member-image {
          $max-image-size: 9em;

          max-width: $max-image-size;
          border-radius: 50%;
          background-size: cover;
          padding-top: unquote("min(" + $max-image-size + ", 33.333%)");
        }

        .member-image, .member-details {
          float: left;

          > div {
              padding-left: $single-space;
              padding-right: $single-space;
          }

          h3 {
              margin: 0;
              margin-bottom: $single-space;
          }
        }
      }

      .alumni {
        margin: $single-space 0;
        margin-left: 0.5em;

        h3 {
          float: left;
          margin: 0;
          margin-right: 1em;
        }

        span {
          color: #666;
          a {
            color: inherit;
          }
        }
      }
    }
  }
}

.side-panel.links > div > a {
  text-decoration: none !important;
  font-size: 2em;
}

.side-panel {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;

    .member-image {
      $max-image-size: 9em;
      height: 0;

      width: $max-image-size;
      border-radius: 50%;
      background-size: cover;
      padding-top: unquote("min(" + $max-image-size + ", 100%)");
    }
}

