$base-red: #a32638;
$light-red: #bc2c41;
$base-black: #333;
$base-gray: #eaeaea;
$base-blue: #7a99ac;

$half-space: 0.7em;
$single-space: 1em;
$double-space: 1.5em;

$default-font-size: 16px;
$default-font-weight: 400;
$bold-font-weight: 700;
$default-line-heigth: 1.3em;
$default-font-family: "Fira Sans";

$small-font-size: 12px;