.projects {
  margin-left: $single-space;
  margin-right: $single-space;

  .project {
    margin-bottom: 2 * $double-space;

    h2 {
        margin-bottom: $half-space / 2;
    }

    .subtitle {
        color: $base-blue;
        margin-bottom: $half-space / 2;
    }

    .content-container {
      @media screen and (max-width: 100em) {
        flex-direction: column;
      }

      .images {
          display: inline;
          float: right;

          img {
              margin: $single-space;
              margin-top: 0;
              margin-right: 0;
          }
      }

      h4 {
          margin-bottom: 0;
      }

      ul {
          margin-top: $half-space / 2;
          margin-left: 0;
          padding-left: 0;

          li {
              list-style-type: none;
          }
      }
    }
  }

  h4 {
    color: $base-black;
  }
}
