.news-list {
  ul {
    padding: 0;

    li {
      list-style: none;
      padding-left: 3px;

      div {
        padding-bottom: $half-space;
        color: $base-black;
        text-decoration: none;
        position: relative;
        display: block;

        h3 {
          color: $base-black;
          margin: 0;
          margin-bottom: $half-space;
        }

        div {
          margin: 0;
          padding: 0;
        }
        
        .date {
          margin-left: $single-space;
          display: inline;
          float: right;
          font-size: $small-font-size;
        }

        img {
          width: 17px;
          position: absolute;
          right: 0;
          bottom: 0;
        }
      }
    }
  }

  .news-scroll-list {
    height: 50vh;
    overflow-y: scroll;
    padding: 0.75em;
    border: 2px solid #eaeaea;
    border-radius: 5px;
  }
  
  @media screen and (min-width: 1280px) {
    .mobile {
      display: none;
    }
  }
  @media screen and (max-width: 1279px) {
    .not-mobile {
      display: none;
    }
  }
}
