.research-seminar {
  ul {
    padding: 0;

    li {
      list-style: none;
      border-bottom: 1px solid $base-gray;
      padding: $single-space 0;
    }
  }
}