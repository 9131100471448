.theses {
    details {
        margin-bottom: $half-space;

        div {
            margin-top: $half-space;
            margin-left: $single-space;
        }
    }

    ul#theses {
        padding: 0;

        li {
            list-style: none;
            margin-top: $single-space;
            margin-bottom: $single-space;

            .thumb {
                position: relative;
                width: 100%;
                max-width: 200px;
                padding-top: 66.67%;
                overflow: hidden;

                img {
                    top: 0;
                    width: 100%;
                    position: absolute;
                    z-index: -10;
                }
            }

            .meta-data > div {
                margin-left: $single-space;

                .title {
                    font-weight: bold;
                }

                .student {

                }

                .degree {
                }
            }
        }
    }

    p {
        margin: 0;
        padding: 0;
    }
}

//Listing of thesis topics

.thesis-topic {
    list-style: none; // Removes bullet points
    padding: 0.3em 0; // Adjust spacing between items
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); // Light separator (optional)
}

// Remove top padding from the first element
.thesis-topic:first-child {
    padding-top: 0;
}


#ongoing-theses-projects,
#available-theses,
#available-projects {
    margin: 0; // Remove extra margin
    padding: 0; // Remove default padding from <ul>
}

.thesis-topic:last-child {
    border-bottom: none; // Remove border for last item
}

.thesis-topic p {
    margin: 0.2em 0;  // Reduce space between paragraphs
    line-height: 1.4; // Adjust line spacing for uniform look
  }
  