@import "normalize.css/normalize";
@import "purecss/build/pure";
@import "purecss/build/base";
@import "purecss/build/grids";
@import "purecss/build/grids-responsive";

$fa-font-path: '/fonts/fontawesome/' !default;
@import "@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "@fortawesome/fontawesome-free/scss/solid.scss";
@import "@fortawesome/fontawesome-free/scss/brands.scss";

@import "academicons/css/academicons";
@import "@fontsource/fira-sans/scss/mixins";
@import "variables";
@import "news";
@import "members";
@import "projects";
@import "theses";
@import "research-seminar";
@import "publications";
@import "toastify";

@include fontFace($weight: $default-font-weight,
  $display: fallback,
  $fontDir: "/fonts/fira-sans"
);

@include fontFace($weight: $bold-font-weight,
  $display: fallback,
  $fontDir: "/fonts/fira-sans"
);

@font-face {
  font-family: 'Academicons';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url('/fonts/academicons/academicons.eot');
  src: url('/fonts/academicons/academicons.eot') format('embedded-opentype'),
    url('/fonts/academicons/academicons.ttf') format('truetype'),
    url('/fonts/academicons/academicons.woff') format('woff'),
    url('/fonts/academicons/academicons.svg') format('svg');
}

.#{$fa-css-prefix},
.fas,
.#{$fa-css-prefix}-solid,
.fab,
.#{$fa-css-prefix}-brands {
  color: $base-black
}

* {
  font-family: $default-font-family, sans-serif;
}

body {
  margin: 0;
  background-color: #fff;
  color: $base-black;
  font-size: $default-font-size;
  line-height: $default-line-heigth;
  text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  min-height: 100vh;
  position: relative;
}

blockquote {
  display: inline-block;
  margin: 0;
  overflow: hidden;
  quotes: "“" "”" "‘" "’";

  &::before {
    color: $base-red;
    float: left;
    margin-right: 0.25em;
    line-height: 1em;
    font-size: 4em;
    content: open-quote;
  }

  p {
    display: block;
    background-color: $base-gray;
    padding: $single-space;
    overflow: hidden;
    margin: 0;
  }
}

a {
  color: $base-blue;

  &:hover {
    text-decoration: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $base-blue;
}

h1,
h2,
h4 {
  font-weight: normal;
}

h3 {
  font-size: 1.125em;
}

header {
  padding: 0em 1em 0;
  text-align: center;
  background-color: $base-gray;
  background: linear-gradient(0deg, #dc6f71 0%, #dc6f71 2em, $base-gray 2em);

  @media screen and (min-width: 48em) {
    display: flex;
    justify-content: center;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
  }

  //Red Header background for smartphones
  @media screen and (max-width: 768px) {
    background: linear-gradient(0deg, #dc6f71 0%, #dc6f71 100%); // Fully red background on mobile
  }

  a {
    color: $base-black;
    text-decoration: none;
  }

  //Tablets etc.
  @media screen and (min-width: 845px) and (max-width: 1084px) {
    .desktop-header-content .links a {
      font-size: 0.90em;
      white-space: nowrap; // Prevent links from breaking into multiple lines
    }
  }

  //This is a workaround for this specific width, the navbar padding extends over the pages width
  //as a result a whitespace is created, this needs css refactoring and improvement later
  @media screen and (min-width: 768px) and (max-width: 845px) {
    .desktop-header-content .links a {
      font-size: 0.80em;
      white-space: nowrap; // Prevent links from breaking into multiple lines
    }
  }

  .desktop-wrapper {
    display: flex;
    margin: 0 auto;
    padding-top: $double-space;
    padding-left: $single-space;
    padding-right: $single-space;
    justify-content: left;
    width: 90%; // default percentage of viewport
    max-width: 1300px;

    @media screen and (min-width: 48em) {
      width: 85%;
    }

    @media screen and (min-width: 64em) {
      width: 80%;
    }

    @media screen and (min-width: 80em) {
      width: 70%;
    }

    @media screen and (min-width: 120em) {
      // 1920px and up
      width: 60%;
      max-width: 1600px; // Increase maximum width
    }
  }

// Dropdown Menu desktop view in header
.dropdown {
  position: relative;
  display: inline-block;

  //visual cue for dropdown
  .dropdown-toggle {
    position: relative;
    padding-left: 1.1em;
    color: #fff;
    cursor: pointer;
  }

  //arrow icon before "Teaching"
  .dropdown-toggle::before {
    content: "▼"; // Unicode down arrow
    font-size: 0.8em;
    position: absolute;
    left: 0; // Position the icon on the left side
    top: 45%;
    transform: translateY(-50%);
    transition: transform 0.2s ease-in-out;
  }

  // Change arrow direction when active
  &.active .dropdown-toggle::before {
    transform: translateY(-50%) rotate(180deg);
  }
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: calc(100% + 0px); // Connect visually, the +x part is if we want a small gap
  background-color: #dc6f71; 
  border-radius: 0px; // rounded corners
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  list-style: none;
  padding: 0.1em 0;
  margin: 0;
  z-index: 1000;
  min-width: 160px;
  border-top: 3px solid darken(#dc6f71, 10%); // Connection border
}

.dropdown-menu li {
  padding: 0.5em 0.8em 0.5em 1.3em; // Left side compensates for the icon
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  transition: background-color 0.3s ease-in-out;
}

.dropdown-menu li:last-child {
  border-bottom: none;
}

.dropdown-menu li a {
  text-decoration: none;
  color: #fff;
  font-weight: normal;
  font-size: 0.95em;
  display: block;
}

.dropdown-menu li:hover {
  background-color: darken(#dc6f71, 10%);
}

.dropdown.active .dropdown-menu {
  display: block;
}

// Responsive adjustments for mobile
@media (max-width: 768px) {
  .dropdown-menu {
    position: static;
    box-shadow: none;
    border-radius: 0;
    border: none;
  }
}
// end of dropdown menu desktop styling



  .main-logo {
    display: flex;
    margin-right: 2em;

    * {
      align-self: center;
    }

    img {
      display: inline-block;
      height: 5em;
    }

  }

  .desktop-header-content {
    height: 6em;
    display: flex;
    flex-direction: column;

    .vcg-text {
      text-align: left;
      font-size: 1.5em;
      margin-bottom: 0.5em;
    }

    .uulm-text {
      text-align: left;
      font-size: 1.5em;
      flex-grow: 1;
    }

    .links {
      display: flex;

      .link-active {
        border-bottom: 0.2em solid $base-red;
        padding-bottom: 0.05em;
      }

      a {
        margin-right: 1em;
        padding-bottom: 0.25em;
        align-self: flex-end;
        box-sizing: content-box;
        color: $base-gray;
      }
    }
  }

  .main-logo-mobile {
    display: flex;
    margin-bottom: 1em;

    * {
      align-self: center;
    }

    img {
      padding-top: 1em;
      height: 2em;
      display: inline-block;
    }

    div {
      display: inline-block;
      text-align: left;
      font-size: 1.25em;
      margin-left: 0.5em;
      padding-top: 1em;
    }
  }

  .mobile-menu-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    min-height: 2.5em;

    .fa-bars {
      font-size: 1.9em;
      /* Icon size */
      line-height: 1;
    }
  }

}

footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: $base-gray;
  display: flex;
  justify-content: center;
  padding: 0.5em 1em 0.5em;

  img {
    height: 2em;
    margin-right: 1em;
    display: inline-block;
    vertical-align: middle;
  }

  .desktop-wrapper {
    display: flex;
    align-items: center;
    margin: 0 auto;
    padding: 0 $single-space;
    justify-content: flex-start;
    width: 90%;
    max-width: 1300px;

    @media screen and (min-width: 48em) {
      width: 85%;
    }

    @media screen and (min-width: 64em) {
      width: 80%;
    }

    @media screen and (min-width: 80em) {
      width: 70%;
    }

    @media screen and (min-width: 120em) {
      // 1920px and up
      width: 60%;
      max-width: 1600px; // Increase maximum width
    }

    .links {
      display: flex;
      align-items: center;

      h4 {
        margin: 0;
        line-height: 2em; // Match the line height with the image height
      }

      a {
        text-decoration: none;
        color: $base-black;
        margin: 0 0.5em;
        vertical-align: middle;
        line-height: 2em; // Match the line height with the image height
      }
    }
  }
}

.hide-on-mobile {
  @media screen and (max-width: 47.999em) {
    display: none !important;
  }
}

.hide-on-desktop {
  @media screen and (min-width: 48em) {
    display: none !important;
  }
}

$mobile-menu-size: 75vw;

.mobile-menu-hidden {
  margin-right: -$mobile-menu-size;
}

.mobile-menu-container {
  display: block;

  position: fixed;
  overflow: hidden;
  right: 0;
  top: 0;
  bottom: 0;
  width: $mobile-menu-size;
  padding: 5% 0;

  background: #fff;
  background: linear-gradient(90deg, #eee 0%, #fff 5%);

  z-index: 1000;

  $transition: all 0.3s ease-out;
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -ms-transition: $transition;
  -o-transition: $transition;
  transition: $transition;

  .mobile-menu-content {
    display: flex;
    flex-direction: column;
    padding-left: 8%;
    height: 100%;

    .mobile-link-side-panel {
      height: 100%;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      margin-top: $single-space;
      flex-grow: 1;

      h4 {
        margin: 0.5em 0;
        font-size: 1.25em;
        font-weight: normal;

        a {
          color: $base-black;
          text-decoration: none;
        }
      }

      .dropdown {
        position: relative;

        .dropdown-toggle {
          font-size: 1.25em;
          color: $base-black;
          cursor: pointer;
          text-decoration: none;
          display: flex;
          align-items: center;
          justify-content: space-between;

          &::after {
            content: "▼";
            font-size: 0.8em;
            transition: transform 0.3s ease-in-out;
          }
        }

        &.active .dropdown-toggle::after {
          transform: rotate(180deg);
        }

        .dropdown-menu {
          display: none;
          list-style: none;
          padding-left: 1em;
          margin-top: 0.5em;

          li {
            margin: 1em 0;

            a {
              font-size: 1.1em;
              color: $base-black;
              text-decoration: none;
              padding-left: 1em;
              display: block;
            }
          }
        }

        &.active .dropdown-menu {
          display: block;
        }
      }

      .space-filler {
        flex-grow: 1;
      }
    }
  }
}

main {
  margin-top: 1em;

  // this provides the space for the footer
  @media screen and (min-width: 48em) {
    padding-bottom: 4.5em;
  }

  .wrapper {
    display: block;
    margin: 0 auto;
    padding-top: $double-space;
    padding-left: $single-space;
    padding-right: $single-space;
    justify-content: center;
    width: 90%; // Use 95% of the viewport width by default
    max-width: 1300px; // Set a maximum width

    @media screen and (min-width: 48em) {
      width: 85%;
    }

    @media screen and (min-width: 64em) {
      width: 80%;
    }

    @media screen and (min-width: 80em) {
      width: 70%;
    }

    @media screen and (min-width: 120em) {
      // 1920px and up
      width: 60%;
      max-width: 1600px; // Increase maximum width
    }

    .link-side-bar {
      float: left;

      a {
        color: $base-black;
        text-decoration: none;
      }

      .page-title {
        .main-logo {
          img {
            display: inline-block;
          }

          div {
            display: inline-block;
          }
        }

        .side-panel {
          margin-top: $double-space;

          h4 {
            margin-top: $single-space;
            margin-bottom: 0;
          }
        }

        .logo {
          padding-top: 30px;
          padding-bottom: 30px;

          img {
            display: block;
            $img-height: 1.75em;
            $img-margin: 1.5em;

            height: $img-height;
            margin-top: $img-margin;

            @media screen and (min-width: 80em) {
              height: $img-height * 1.25;
            }

            @media screen and (min-width: 100em) {
              height: $img-height * 1.5;
            }
          }
        }
      }
    }

    .main-content {
      @media screen and (max-width: 47.999em) {
        margin-bottom: 2em;
      }

      float: left;

      h1 {
        margin-top: 0;
        line-height: $double-space;
      }

      >div {
        margin-left: $single-space;
        margin-right: $single-space;
      }
    }

    .side-bar {
      box-sizing: border-box;

      .side-bar-wrapper {
        margin-left: 0%;
        float: left; //Change sidebar to right if too close
        padding: $single-space;
        background-color: $base-gray;
      }

      .banner-image {
        //margin-left: $single-space;
        //margin-right: $single-space;
        margin-bottom: $single-space;
        display: flex;

        img {
          width: 100%;
        }
      }

      h3,
      h4 {
        margin-top: 0;
        color: $base-black;
      }

      .member-details {
        img {
          width: 100%;
        }

        h4 {
          font-weight: $default-font-weight;
          margin: 1em 0;
          position: relative;
          opacity: 0.9;
        }

        .info-title {
          margin-top: 0.5em;
          font-size: 0.8em;
        }
      }

      .side-panel {
        margin-bottom: 1.5rem;
        text-align: right;

        div {
          //background-color: $base-gray;
          //padding-right: $single-space;

          h4 {
            margin: 0;
            font-weight: $default-font-weight;
          }

          a {
            color: $base-blue;
            text-decoration: underline;
          }

          .headline {
            padding: 0;
            font-weight: bolder;
          }
        }
      }
    }
  }
}