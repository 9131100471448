.theses {
    details {
        margin-bottom: $half-space;

        div {
            margin-top: $half-space;
            margin-left: $single-space;
        }
    }

    ul#theses {
        padding: 0;

        li {
            list-style: none;
            margin-top: $single-space;
            margin-bottom: $single-space;

            .thumb {
                position: relative;
                width: 100%;
                max-width: 200px;
                padding-top: 66.67%;
                overflow: hidden;

                img {
                    top: 0;
                    width: 100%;
                    position: absolute;
                    z-index: -10;
                }
            }

            .meta-data > div {
                margin-left: $single-space;

                .title {
                    font-weight: bold;
                }
            }
        }
    }

    p {
        margin: 0;
        padding: 0;
    }
}

// Open & Ongoing listings styling
#open-theses-projects ul,
#ongoing-theses-projects ul {
  padding: 0;
  margin: 0;

  li {
    list-style: none;
    margin-top: $single-space;
    margin-bottom: $single-space;

    .thumb {
      position: relative;
      width: 100%;
      max-width: 200px;
      padding-top: 66.67%;
      overflow: hidden;

      img {
        top: 0;
        width: 100%;
        position: absolute;
        z-index: -10;
      }
    }

    .meta-data > div {
      margin-left: $single-space;

      .title {
        font-weight: bold;
      }

      .student,
      .degree {
        margin-bottom: 0.2em;
      }
    }

    p {
      margin: 0.2em 0;
      line-height: 1.4;
    }
  }
}

//styling of the available projects/theses lists on the projects and theses pages
ul#available-theses,
ul#available-projects {
  padding: 0;
  margin: 0;

  .thesis-topic {
    margin: 0.5em 0;
    list-style: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0.3em 0;

    p {
      margin: 0em 0;
      line-height: 1.4;
    }

    h3 {
      margin: 0.2em 0 0.4em 0;
    }
  }

  .thesis-topic:last-child {
    border-bottom: none;
  }

  .thesis-topic:first-child {
    padding-top: 0;
  }
}

